<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">เพิ่มข้อมูลการลา</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="form.activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อผู้ลา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="form.name"
              label="ชื่อผู้ลา"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">สาเหตุการลา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="form.description"
              label="สาเหตุการลา"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ประเภทการลา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              v-model="form.type"
              label="ประเภทการลา"
              solo
              dense
              :rules="rules.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">เบอร์โทรศัพท์ผู้ลา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="form.tel"
              label="เบอร์โทรศัพท์ผู้ลา"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">วันที่เริ่มลา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              label="วันที่เริ่มลา"
              v-model="form.startDate"
              type="date"
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">วันที่สิ้นสุดลา</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              label="วันที่สิ้นสุดลา"
              v-model="form.endDate"
              type="date"
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>แนบไฟล์</span>
                  <input
                    type="file"
                    accept=".csv"
                    id="file"
                    ref="file"
                    v-on:change="handleFileUpload()"
                  />
                  <v-btn
                    color="#cf5536"
                    style="color: white"
                    v-on:click="submitFile()"
                    >upload</v-btn
                  ></v-col
                >
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="#234baa" dark >บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
        type: "",
        tel: "",
        startDate: "",
        endDate: "",
        imgUrl: "",
        file: "",
        activeFlag: true,
      },
      items: [],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    this.getAllProduct();
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/
          `
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
    },
    // async getAllProduct() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllproducts", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("Manageleave");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/banners1111`,
          this.form,
          auth
        );
        // console.log("createCate", response);
        // console.log(response.data.response_status);
        // if (response.data.response_status == "SUCCESS") {
        //   this.$swal.fire({
        //     icon: "success",
        //     text: `สร้างแบนเนอร์สำเร็จ`,
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        //   this.$router.push("Managebanners");
        // } else {
        //   this.$swal.fire({
        //     icon: "error",
        //     text: `สร้างแบนเนอร์ไม่สำเร็จ`,
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        // }
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("file", file);
      console.log(this.type);
    },
    async submitFile() {
      if (this.$refs.formupload.validate(true)) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("type", this.type);
        formData.append("companyId", this.id);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        this.uploaded = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API}/xxxx/formData`,
          formData,
          auth
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response.data);
        this.uploaded = false;
        if (response.data.response_status == "SUCCESS") {
          await this.$swal
            .fire({
              text: "อัพโหลดข้อมูลสำเร็จแล้ว กดเพื่อดูข้อมูล",
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ดูข้อมูล",
              cancelButtonText: "ยกเลิก",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push(this.type);
              }
            });
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
      }
    },
  },
};
</script>
